import React, {Fragment} from "react";
import {css} from "@emotion/react";

export interface IProps {
    imgAlt?: string;
    onClick: () => void;
    srcSm?: string;
    srcMd?: string;
    srcLg?: string;
}

export const GalleryPicture = (props: IProps) => {
    return (
        <picture css={galleryImageHolder}>
            {props.srcLg && <source media="(min-width: 1920px)" srcSet={props.srcLg} />}
            {props.srcMd && (
                <Fragment>
                    <source media="(min-width: 1400px)" srcSet={props.srcMd} />
                    <source media="(min-width: 480px)" srcSet={props.srcMd} />
                </Fragment>
            )}
            {props.srcSm && (
                <Fragment>
                    <source media="(min-width: 1024px)" srcSet={props.srcSm} />
                    <source media="(min-width: 768px)" srcSet={props.srcSm} />
                </Fragment>
            )}
            <img src={props.srcSm} alt={props.imgAlt} loading="lazy" onClick={props.onClick} />
        </picture>
    );
};

const galleryImageHolder = css`
    display: block;

    > img {
        margin-bottom: 2rem;
        vertical-align: middle;
        width: 100%;
        cursor: pointer;
    }
`;
