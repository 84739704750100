import React, {useCallback, useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {gridG} from "@pg-design/helpers-css";

import {IGalleryImage} from "../types/IGalleryImage";
import {GalleryPicture} from "./GalleryPicture";

interface IProps {
    getImageAlt: (index: number) => string;
    images: IGalleryImage[];
    onImageClick: (index: number) => void;
}

export const GalleryPicturesGrid = (props: IProps) => {
    const renderGalleryPicture = useCallback((index: number, image: IGalleryImage) => {
        return <GalleryPicture key={index} onClick={() => props.onImageClick(index)} {...image} />;
    }, []);

    if (!props.images.length) {
        return null;
    }

    const {evenImages, oddImages} = useMemo(() => {
        return props.images.reduce<{oddImages: IGalleryImage[]; evenImages: IGalleryImage[]}>(
            (acc, image, index) => {
                const imageWithAlt = {...image, imgAlt: props.getImageAlt(index)};

                index % 2 === 0 ? acc.evenImages.push(imageWithAlt) : acc.oddImages.push(imageWithAlt);
                return acc;
            },
            {evenImages: [], oddImages: []}
        );
    }, [props.images]);

    return (
        <div css={galleryRow}>
            <div css={galleryColumn}>{evenImages.map((image, index) => renderGalleryPicture(index * 2, image))}</div>

            <div css={galleryColumn}>{oddImages.map((image, index) => renderGalleryPicture(index * 2 + 1, image))}</div>
        </div>
    );
};

const galleryRow = (theme: Theme) => css`
    display: grid;

    @media (min-width: ${theme.breakpoints.sm}) {
        ${gridG(2)}
        grid-template-columns: 1fr 1fr;
    }
`;

const galleryColumn = css`
    max-width: 100%;
`;
